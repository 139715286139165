import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ContactPopup from "../components/ContactPopup"
import { getActiveLanguage } from "../utils/helper"
import Button from "../components/Button"
import { useStoryblokState } from "gatsby-source-storyblok"
import Page from "../components/Page"
export default function Page404({ data, location }) {
  const lang = getActiveLanguage(location)
  const node = data.allPages?.edges?.find(i => i.node.lang === lang)?.node || {}
  const story = useStoryblokState(node, location)


useEffect(() => {
  document.body.classList.add('notFoundPage');
  return ()=>{ document.body.classList.remove('404page') }
},[])
  return (
    <Layout location={location} enableGetinTouch={story?.content?.get_in_touch_section}>
        <Page blok={story?.content} fourofour/>
    </Layout>
  )
}

export const query = graphql`
  {
      allPages: allStoryblokEntry(filter : {slug: {eq: "404"}}) {
      edges {
        node {
          content
          field_component
          id
          full_slug
          lang
          name
          internalId
        }
      }
    }
  }
`